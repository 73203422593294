.product-card {
  padding: 0.6rem;
  margin: 0.2rem;
  /* border: 1px solid #eee; */
  border: 1px solid #555;
  opacity: 0.25;
  color: #fff;
  /* min-height: 8rem; */
  /* background-color: #ccc; */
  width: 18%;
}

.product-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.product-desc {
  width: 70%;
}

.product-name,
.product-color {
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  /* line-height: 1.2em; */
  padding: 0;
  margin: 0.2rem 0;
  /* background-color: #000; */
}

.product-price {
  font-size: 1.3em;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.product-name {
  font-size: 0.8em;
}

.product-color {
  font-size: 0.5em;
}

.product-image {
  /* width: 100%; */
  background-color: #fff;
  padding: 10%;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  height: auto;
  width: 60%;
  /* opacity: 0.2; */
}

@media all and (max-width: 600px) {
  .product-card {
    margin: 0.1rem;
    min-height: 10rem;
    width: 33%;
  }

  .product-name {
    font-size: 0.6em;
  }

  .product-color {
    font-size: 0.4em;
  }

  .product-price {
    font-size: 1em;
    /* background-color: pink; */
  }
}
