.overlay-container {
  z-index: 2;
  position: absolute;
  background-color: #fff;
  /* background-color: rgba(155, 8, 8, 1); */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  width: 60vw;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.overlay-logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.app-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.background-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

@media all and (max-width: 600px) {
  .overlay-container {
    width: 80vw;
  }

  .overlay-logo {
    justify-content: center;
  }
}
