.logo-block {
  width: 120px;
  height: 120px;
}

.logo-block > svg {
  height: 100%;
  width: 100%;
}

.logo .text {
  /* fill: #ffffff; */
  fill: #000;
}

.logo .blade {
  /* fill: #000000; */
  fill: rgba(190, 0, 0, 1);
}
