.countdown-text,
.countdown-header {
  /* color: #fff; */
  color: #333;
}

.countdown-header,
.store-code {
  font-size: 2em;
  font-weight: 600;
}

.countdown-header {
  margin-bottom: 1rem;
}

.basic-store-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.4em;
  color: rgb(190, 0, 0, 1);
}

.basic-store-link {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.countdown-text {
  font-size: 0.9em;
  font-weight: 400;
  text-align: center;
  margin-top: 1em;
}

.countdown-subheader {
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
}

.store-code {
  display: block;
  /* border: 1px solid #fff; */
  border: 1px solid #333;
  border-radius: 8px;
  padding: 1rem 2rem;
  margin: 1rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.store-button {
  display: inline-block;
  /* background-color: #000; */
  background-color: rgb(190, 0, 0, 1);
  border-radius: 1rem;
  padding: 1rem 3rem;
  color: #fff;
  font-weight: 800;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.store-button-icon {
  padding-left: 20px;
}

.store-button:hover {
  background-color: rgb(230, 0, 0, 1);
}

.copy-button {
  cursor: pointer;
  color: rgba(155, 8, 8, 1);
  /* color: #fff; */
}

.copy-button:hover {
  color: rgb(220, 0, 0, 1);
}

@media all and (max-width: 600px) {
  .countdown-header,
  .store-code {
    font-size: 1.5em;
  }

  .countdown-header {
    margin-bottom: 0.4rem;
  }
}
